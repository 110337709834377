import React from 'react'
import Card from '../components/Card'
import Grid from '../components/Grid'
import Header from '../components/Header'
import Layout from '../components/layout'
import Wrapper from '../styles/Wrapper'
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout title="Specialisaties" content="Je kunt bij mij terecht met uiteenlopende vragen en problemen gerelateerd aan voeding. Mijn focus ligt op mensen handvatten geven voor een gezonder, fitter en vitaler lichaam.">
    <Header />

    <Wrapper>
      <h1 css={{ textAlign: 'center' }}>Specialisaties</h1>

      <p
        css={{
          margin: '0 auto 4rem',
          maxWidth: 840,
          textAlign: 'center',
        }}
      >
        Je kunt bij mij terecht met uiteenlopende vragen en problemen
        gerelateerd aan voeding. Mijn focus ligt op mensen handvatten geven voor
        een gezonder, fitter en vitaler lichaam. Met behulp van normale, gezonde
        voeding gaan we op zoek naar een duurzaam voedingspatroon dat bij jou
        past, je alle voedingsstoffen levert die je lichaam nodig heeft en zorgt
        voor een langdurig resultaat. Zónder dieet hypes, maar met een
        realistisch advies.
      </p>

      <Grid>
        {data.allMarkdownRemark.edges.map(specialisation => (
          <Card
            key={specialisation.node.frontmatter.path}
            title={specialisation.node.frontmatter.title}
            content={specialisation.node.frontmatter.description}
            moreLabel={specialisation.node.frontmatter.more}
            slug={specialisation.node.frontmatter.path}
          />
        ))}
      </Grid>
    </Wrapper>
  </Layout>
)

export const query = graphql`
  query Specialisations {
    allMarkdownRemark(
      filter: { frontmatter: { path: { regex: "/specialisaties/.+/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            more
            path
          }
        }
      }
    }
  }
`
